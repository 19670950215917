import React, { useState, useEffect } from "react";
import ItemType from "../../components/Item/ItemType";
import { Container, Button } from "reactstrap";
import TruckFormFields from "../../components/Forms/TruckFormFields";
import MachineryFormFields from "../../components/Forms/MachineryFormFields";
import CarFormFields from "../../components/Forms/CarFormFields";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import {
  DEFAULT_CAR_FORM,
  DEFAULT_MACHINERY_FORM,
  DEFAULT_MANY,
  DEFAULT_TRUCK_FORM,
} from "../../components/Forms/constants";
import { confirmAlert } from "../../components/ui/ConfirmAlert";
import useCreateResource from "../../api/services/useCreateResource";
import usePageTitle from "../../hooks/usePageTitle";
import ManyItemsFormFields from "../../components/Forms/ManyItemsFormFields";
const typeMap = {
  1: "car",
  2: "truck",
  3: "machinery",
  4: "many",
};
const DEFAULT_MAP = {
  car: DEFAULT_CAR_FORM,
  truck: DEFAULT_TRUCK_FORM,
  machinery: DEFAULT_MACHINERY_FORM,
  many: DEFAULT_MANY,
};

const CreateItemForm = () => {
  usePageTitle("BGL - Ingreso de Bienes");
  const history = useHistory();
  const { mutate, isLoading } = useCreateResource({ resource: "/item/form" });
  const formMethods = useForm({
    defaultValues: {
      test: "test",
    },
  });

  const [formType, setformType] = useState(0);

  const formComponents = [
    <ItemType setValue={setformType} />,
    <CarFormFields />,
    <TruckFormFields />,
    <MachineryFormFields />,
    <ManyItemsFormFields />,
  ];

  useEffect(() => {
    if (DEFAULT_MAP[typeMap[formType]]) {
      formMethods.reset(DEFAULT_MAP[typeMap[formType]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formType]);

  const onSubmit = 
   
    formMethods.handleSubmit((formData) => {
      confirmAlert({
        title: "Guardar",
        question: "¿Estas seguro que quieres guardas este bien?",
        confirmText: "Confirmar",
        onConfirm: async () => {

          console.log("forMethods", formMethods.formState);
          if (formMethods.formState.isSubmitting) return;
          
          mutate(
            { data: formData },
            {
              onSuccess: (data) => {
                formMethods.setValue("isSubmitting", false);
                history.push(`/item-photo/${data.numerointerno}`);
              },
              onError: () => {
                console.log("onError");
                
                formMethods.setValue("isSubmitting", false);
              },
            }
          );
        },
      });
    });
  
   
  return (
    <Container className={"py-4"}>
      <FormProvider {...formMethods}>
        <form onSubmit={onSubmit}>
          {formType !== 0 && (
            <Button color="success" type="submit">
              Guardar
            </Button>
          )}
          {formComponents[formType]}
        </form>
      </FormProvider>
    </Container>
  );
};

export default CreateItemForm;
