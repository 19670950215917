import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import useEventListener from "../../lib/EventEmitter/useEventListener";
import eventEmitter from "../../lib/EventEmitter/EventEmitter";

const MODAL_KEY = "MODAL KEY ASMDBNKASD ALERT";
/**
 * Función para emitir un evento que muestra el modal de confirmación.
 * @param {Object} props - Propiedades para configurar el modal de confirmación.
 * @param {string} props.title - Título del modal.
 * @param {string} props.question - Pregunta que se mostrará en el cuerpo del modal.
 * @param {string} props.confirmText - Texto que aparecerá en el botón de confirmación.
 * @param {function} props.onConfirm - Función asíncrona que se ejecuta cuando se confirma la acción.
 * @param {function} props.onCancel - Función asíncrona que se ejecuta cuando se cancela la acción.
 */
export const confirmAlert = (props) => eventEmitter.emit(MODAL_KEY, props);

const ConfirmAlert = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmState, setConfirmState] = useState({
    title: "",
    question: "¿Estas seguro que quieres guardas este bien?",
    confirmText: "Guardar",
    onConfirm: async () => null,
    onCancel: async () => null,
  });
  const toggle = () => setIsOpen((state) => !state);

  const onConfirm = async () => {
    console.log("onConfirm");
    await confirmState.onConfirm?.();
    setIsOpen(false);
  };

  const onCancel = async () => {
    await confirmState.onCancel?.();
    setIsOpen(false);
  };
  useEventListener(
    (data) => {
      setConfirmState({ ...data });
      setIsOpen(true);
    },
    [MODAL_KEY]
  );
  return (
    <Modal
      isOpen={isOpen}
      modalTransition={{ timeout: 300 }}
      backdropTransition={{ timeout: 300 }}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Guardar</ModalHeader>
      <ModalBody>{confirmState.question}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onConfirm}>
          {confirmState.confirmText}
        </Button>
        <Button color="secondary" onClick={onCancel}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmAlert;
