import { types, API_URL } from "./types";
import Axios from "axios";

export const signIn = (email, password) => {
  const url = `${API_URL}/api/auth/sign_in`;

  return (dispatch) => {
    let outputData = {};
    let params = {
      email: email,
      password: password,
    };
    Axios.post(url, params).then(({ data }) => {
      if (data.error) {
        outputData["status"] = "danger";
        outputData["message"] = "Usuario o clave incorrecta!";
        dispatch(setSignIn(outputData));
      } else {
        localStorage.setItem("token", data["AUTH-TOKEN"]);
        localStorage.setItem("role", data["role"]);
        outputData["token"] = data["AUTH-TOKEN"];
        outputData["status"] = data.status;
        outputData["message"] = data.message;
        dispatch(setSignIn(outputData));
      }
    });
  };
};

export const setSignIn = (data) => ({
  type: types.signIn,
  payload: data,
});

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("role");

  return (dispatch) => {
    dispatch(setLogout());
  };
};

export const setLogout = () => {
  return {
    type: types.signOut,
    payload: { token: null, status: null, message: null, signIn: null },
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(logout());
    }
    dispatch(setSignIn({ token: token }));
  };
};
